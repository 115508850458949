<template>
  <CRow>
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="4" xl="4" class="pt-0 pb-0 text-left">
              Timed Moments
            </CCol>
            <CCol cols="8" xl="8" class="pt-0 pb-0 text-xl-right">
              <div class="mt-1 mt-xl-0 d-inline-block align-top">
                <CButton class="ml-1 link" color="primary" @click="newTimedMomentModal = true; resetTimedMomentData();">
                  <i class="fas fa-plus mr-1"/>
                  <span>Nieuwe Timed Moment</span>
                </CButton>                
              </div> 
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="pb-0">
          <CRow>
            <CCol cols="12" lg="12" class="p-0">
              <b-tabs class="timed_moments_tabs no_borders mb-0" type="is-boxed" :animated="false">
                <b-tab-item>
                  <template slot="header">
                    <span><i class="icon vertical m-0 fas fa-table mr-1"/>Overzicht</span>
                  </template>
                  <template>
                    <CRow>
                      <CCol cols="12" lg="12" class="p-0">
                        <b-table class="data_table" :data="timedMoments" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition">
                          <template slot-scope="props">
                            <b-table-column field="label" label="Label">                            
                              <div class="moment mr-1" v-bind:style="{ background: getColor(props.row.tm_type_id) }">
                                <i v-bind:class="getIcon(props.row.tm_type_id)"/>
                              </div> {{props.row.label}}
                            </b-table-column>
                            <b-table-column field="description" label="Beschrijving">
                              {{props.row.description}}
                            </b-table-column>
                            <b-table-column field="department_team" label="Afdeling/Team">
                              {{props.row.department_team}}
                            </b-table-column>
                            <b-table-column field="date_created" label="Aangemaakt op">
                              {{props.row.date_created | moment("DD-MM-YYYY HH:mm")}}
                            </b-table-column>                  
                          </template>
                          <template slot="empty">
                            <div class="p-2 text-center">
                              <span>Geen Timed Moments gevonden</span>
                            </div>
                          </template>
                        </b-table>
                      </CCol>
                    </CRow>
                  </template>
                </b-tab-item>
                <b-tab-item>
                  <template slot="header">
                    <span><i class="icon vertical m-0 fas fa-list-ul mr-1"/>Gebeurtenissen</span>
                  </template>
                  <template>
                    <CRow>
                      <CCol cols="12" lg="12" class="p-0">
                        <div class="timeline timed_moments v-application v-application--is-ltr">
                          <v-timeline :align-top="true" :reverse="true" :dense="$vuetify.breakpoint.smAndDown">
                            <v-timeline-item v-for="moment in timedMoments" :key="moment.timed_moment_id" :color="getColor(moment.tm_type_id)" :icon="getIcon(moment.tm_type_id)" fill-dot>           
                              <v-card :color="getColor(moment.tm_type_id)">
                                <v-card-title class="mb-0">
                                  <v-row>
                                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" class="pt-0 pb-0 mb-0 text-left label">
                                      <span>{{moment.label}}</span>
                                    </v-col>
                                    <v-col cols="12" xl="6" lg="6" md="6" sm="6" class="pt-0 pb-0 text-right date">
                                      <span>{{moment.date | moment("DD-MM-YYYY")}}</span>
                                    </v-col>
                                  </v-row>
                                </v-card-title>
                                <v-container class="pt-0 pb-0">
                                  <v-row class="content">
                                    <v-col cols="12" xl="8" lg="6" md="12" sm="12" class="description">                            
                                      <p class="mb-0">{{moment.description}}</p>
                                    </v-col>
                                    <v-col cols="12" xl="4" lg="6" md="12" sm="12"> 
                                      <v-row>
                                        <v-col cols="12" lg="6" md="6" sm="6" class="pt-0 pb-0">
                                          <div class="overall_score text-center">
                                            <span class="integral">{{ getScoreIntegral(moment.current_overall_score) }}</span>
                                            <span class="decimal">.</span>
                                            <span class="fractional">{{ getScoreFracetional(moment.current_overall_score) }}</span>
                                          </div>              
                                        </v-col>
                                        <v-col cols="12" lg="6" md="6" sm="6" class="pt-0 pb-0">
                                          <div class="score text-center" v-bind:class="{increased : moment.score_difference > 0, decreased : moment.score_difference < 0}">
                                            <i class="icon fas" v-bind:class="{'fa-arrow-circle-up': moment.score_difference > 0, 'fa-arrow-circle-down': moment.score_difference < 0}"/>
                                            <span class="growth ml-1">{{moment.score_difference}}</span>
                                          </div>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card>
                            </v-timeline-item>
                          </v-timeline>
                        </div>              
                      </CCol>
                    </CRow>
                  </template>
                </b-tab-item>        
              </b-tabs>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>    

      <b-modal class="tasks" :can-cancel="['x']" :active.sync="newTimedMomentModal" :width="960" scroll="keep">
        <CCard class="mb-0">
          <CCardHeader class="pb-0">
            Nieuwe Timed Moment
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol cols="6" lg="6" class="pt-0 pb-0">
                <CSelect class="mb-0" label="Voor welke groep is de Timed Moment bestemd?" :value.sync="newTimedMoment.tm_group_id" :options="groups" placeholder="Selecteer een groep" required was-validated @update:value="newTimedMoment.group_id = null"/>
              </CCol>
              <CCol cols="6" lg="6" class="pt-0 pb-0">
                <CSelect v-if="newTimedMoment.tm_group_id === 2" class="mb-0" label="Voor welke afdeling?" :value.sync="newTimedMoment.group_id" :options="departments" placeholder="Selecteer een afdeling" required was-validated/>
                <CSelect v-if="newTimedMoment.tm_group_id === 3" class="mb-0" label="Voor welk team?" :value.sync="newTimedMoment.group_id" :options="teams" placeholder="Selecteer een team" required was-validated/>
              </CCol>              
            </CRow>
            <CRow>
              <CCol cols="12" lg="12" class="pb-0">                
                <CSelect class="mb-0" label="Om welk type Timed Moment gaat het?" :value.sync="newTimedMoment.tm_type_id" :options="types" placeholder="Selecteer een type" required was-validated/>
              </CCol>
            </CRow>
            <CRow>
              <CCol cols="12" lg="12" class="pb-0">                
                <label class="w-100">Beschrijving Timed Moment<span class="countdown" v-if="remainingCount > 0">{{remainingCount}} karakter(s) resterend</span></label>
                <CTextarea class="mb-0" @input="countdown()" v-model="newTimedMoment.description" rows="3" :maxlength="maxCount" required was-validated/>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <div>
              <CButton color="primary" @click="insertTimedMoment()"><i class="fas fa-save mr-1"/>{{$t('Save')}}</CButton>
              <CButton color="secondary" @click="newTimedMomentModal = false"><i class="fas fa-times mr-1"/>{{$t('cancel')}}</CButton>
            </div>
          </CCardFooter>          
        </CCard>
      </b-modal>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';

export default {
  name: 'TimedMoments',
  components: {
    QuickEdit
  },
  data() {
    return {
      timedMoments: [],                  
      groups: [],
      departments: [],
      teams: [],
      types: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 15,
      newTimedMoment: {},
      newTimedMomentModal: false,
      maxCount: 250,
      remainingCount: 250
    }
  },
  methods: {
    insertTimedMoment () {
      let params = {};
      params.description = this.newTimedMoment.description;
      params.tm_group_id = this.newTimedMoment.tm_group_id;
      params.group_id = this.newTimedMoment.group_id;
      params.tm_type_id = this.newTimedMoment.tm_type_id;

      let tm_group_id = params.tm_group_id;
      let tm_type_id = params.tm_type_id;
      let description = params.description;

      if(tm_group_id && tm_type_id && description) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/core/timed_moment', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('timed_moments.Timed_moment_added'), type: 'is-success', duration: 2000 });
          this.getTimedMoments();
          this.resetTimedMomentData();
          this.newTimedMomentModal = false;
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    getTimedMoments () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/timed_moments')
      .then(res => {
        this.timedMoments = res.data.data;
                
        for(var index = 0; index < this.timedMoments.length; index++) {
          (index < this.timedMoments.length - 1) ? this.timedMoments[index].score_difference = parseFloat(this.timedMoments[index].current_overall_score - this.timedMoments[index+1].current_overall_score).toFixed(1) : this.timedMoments[index].score_difference = null;
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getColor(type) {
      if(type === 1) {
        return 'var(--DashboardSecondary)';
      } else if(type === 2) {
        return 'var(--DashboardPrimary)';
      } else if(type === 3) {
        return 'var(--DashboardLightGreen)';
      } else if(type === 4) {
        return 'var(--DashboardDarkGreen)';
      }
    },
    getIcon(type) {
      if(type === 1 || type == 2) {
        return 'fas fa-trophy';
      } else if(type === 3 || type == 4) {
        return 'fas fa-user-plus';
      }           
    },
    getScoreIntegral(score) {
      if(typeof score === 'string' || score instanceof String) {
        return score.substring(0, score.indexOf('.'));
      } else {
        score = score.toFixed(1).toString();
        return score.substring(0, score.indexOf('.'));
      }
    },
    getScoreFracetional(score) {
      if(typeof score === 'string' || score instanceof String) {
        return score.substring(score.indexOf('.') + 1);
      } else {
        score = score.toFixed(1).toString();
        return score.substring(score.indexOf('.') + 1);
      }
    },
    resetTimedMomentData () {  
      this.newTimedMoment = {
        tm_group_id: null,        
        group_id: null,
        tm_type_id: null,
        description: null
      };
      this.remainingCount = 250;
    },
    countdown () {
      this.remainingCount = this.maxCount - this.newTimedMoment.description.length;
    }
  },
  mounted: function(){
    this.getTimedMoments();
    this.resetTimedMomentData();

    if(localStorage.getItem('userLevel') <= 2){
      this.groups = [
        {value: 1, label: 'Organisatie'},
        {value: 2, label: 'Afdeling'},
        {value: 3, label: 'Team'}
      ];
    } else if(localStorage.getItem('userLevel') <= 3){
      this.groups = [
        {value: 1, label: 'Organisatie'},
        {value: 2, label: 'Afdeling'}
      ];
    } else {
      this.groups = [
        {value: 3, label: 'Team'}
      ];
    }

    axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/departmentTeams/my')
      .then(res => {
        // Get management result from API response                
        var departmentTeams = res.data.data;

        // Empty departments and teams arrays
        this.departments = [];
        this.teams = [];
      
        var lookup = {};
        // Loop through management result and add unique departments to departments array
        for (var departmentTeam, index = 0; departmentTeam = departmentTeams[index++];) {
          var departmentId = departmentTeam.department_id;

          if (!(departmentId in lookup)) {
            lookup[departmentId] = 1;
            this.departments.push({
              value: departmentTeam.department_id,
              label: departmentTeam.department_name,
            });
          }
        }
        // Loop through management result and add unique teams to teams array
        for (var departmentTeam, index = 0; departmentTeam = departmentTeams[index++];) {
          var teamId = departmentTeam.team_id;

          if (!(teamId in lookup)) {
            lookup[teamId] = 1;
            this.teams.push({
              value: departmentTeam.team_id,
              label: departmentTeam.team_name,
            });
          }
        }
      })
      .catch(err => {
        console.error(err); 
    }); 

    axios.get(process.env.VUE_APP_API_URL + '/v1/core/timed_moments/types')
    .then(res => {
      this.types = res.data.data;
    })
    .catch(err => {
      console.error(err); 
    });
  }
}
</script>